<template>
  <header id="site-header">
    <figure class="logo">
      <a href="/">
        <img src="/images/common/logo.webp" alt="d-dining" />
      </a>
    </figure>
    <nav class="gnavi" role="navigation" itemscope="itemscope" itemtype="http://scheme.org/SiteNavigationElement">
      <ul>
        <li itemprop="name"><a itemprop="url" href="/about/">会社概要</a></li>
        <li itemprop="name"><a itemprop="url" href="/store/">店舗一覧</a></li>
        <li itemprop="name"><a itemprop="url" href="/recruit/">採用情報</a></li>
      </ul>
    </nav>
    <a href="" class="btn">求人エントリー</a>
  </header>
</template>

<style lang="scss" scoped>
header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.32rem side();
  background: $white;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  transition: all 200ms $ease;
  &.active{
    padding-top: .8rem;
    padding-bottom: .8rem;
    box-shadow: 0 0 .32rem rgba($normal,.08);
  }
  > *{
    align-self: center;
  }
  .logo{
    width: 11rem;
    justify-self: flex-start;
    img{
      width: 100%;
    }
  }
  nav{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ul{
      @include flex();
      justify-content: center;
      align-items: center;
      li{
        font-size: 1.04rem;
        margin: 0 2rem 0 0;
        padding: 0 2rem 0 0;
        position: relative;
        &:after{
          content: '';
          width: 1px;
          height: 64%;
          border-left: $normal 1px dotted;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(50%, -50%) skewX(-20deg);
          opacity: .32;
          @media screen and (max-width: $tab){
            display: none;
          }
        }
        &:last-child{
          margin-right: 0;
          padding-right: 0;
          &::after{
            display: none;
          }
        }
        a{
          color: $normal;
          font-weight: 500;
          &:hover{
            color: $sub;
          }
        }
      }
    }
  }
  .btn{
    justify-self: flex-end;
    @include btn($white, $sub);
  }
}
</style>

<script>
export default {
}
</script>
