import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'
export default function index(){
  const fv = document.getElementById('firstview')
  const trg = document.getElementById('scrollArea')
  if(fv){
    document.querySelector('.firstview__inner__en').classList.add('active')

  }
}