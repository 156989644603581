import * as imagesLoaded from 'imagesloaded'
import GsapSmoothScroll from './class/GsapSmoothScroll'
import common from './common'
import index from './index'
import partials from './partials'

let smoother
document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('app'), () => {

    partials().then(() => {
      common()
      index()
      // smoother = new GsapSmoothScroll("#scrollArea", null, 1.6)
      setProp()
      initContactForm()
    })
  })
})

function setProp() {
  if (smoother != null) {
    document.documentElement.style.setProperty('--screen', `${smoother.getOffsets()}px`)
  } else {
    cancelAnimationFrame(setProp)
  }
  requestAnimationFrame(setProp)
}

function initContactForm() {
  setTimeout(()=>{
    const cf_selector = 'div.wpcf7 > form'
    const cf_forms = document.querySelectorAll(cf_selector)
    
    if (cf_forms.length > 0) {
      const cf_load = document.querySelector('.wpcf7-spinner')
      cf_load.remove()
      window.wpcf7.init(cf_forms[0])
    }
  },1000)
}



