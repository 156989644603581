// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-18c2bfa6]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-18c2bfa6]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-18c2bfa6]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-18c2bfa6]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
.hero[data-v-18c2bfa6] {
  position: relative;
}
.hero .circle[data-v-18c2bfa6] {
  position: absolute;
  right: calc(calc(var(--size) + 2vw) * 0.5);
  bottom: calc(calc(var(--size) + 2vw) * -0.5);
  width: 10rem;
  z-index: 3;
  animation: rotation-18c2bfa6 8000ms linear infinite;
}
@keyframes rotation-18c2bfa6 {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
.hero .title[data-v-18c2bfa6] {
  position: relative;
  margin: var(--head) 0 0;
  line-height: 1;
  padding: 7.2rem calc(calc(var(--size) + 2vw) * 1.66) calc(var(--head) + 7.2rem);
  overflow: hidden;
}
.hero .title[data-v-18c2bfa6] > * {
  position: relative;
  align-self: flex-end;
  z-index: 3;
}
.hero .title[data-v-18c2bfa6]:before {
  content: "";
  width: 41%;
  height: 150%;
  background: #f3f4f2;
  position: absolute;
  top: -25%;
  left: 0;
  z-index: 2;
  border-radius: 0 999rem 999rem 0;
}
.hero .title > div[data-v-18c2bfa6] {
  transform: translateY(2rem);
}
.hero .title > div p[data-v-18c2bfa6] {
  font-family: "Outfit", sans-serif;
  font-size: 1.72rem;
  font-weight: 600;
  color: #db0007;
}
.hero .title > div h1[data-v-18c2bfa6] {
  font-size: 3.2rem;
  margin: 2vmin 0 0;
  letter-spacing: 0.32vmin;
  transform: translateX(-0.32vmin);
}
.hero .title img[data-v-18c2bfa6] {
  position: absolute;
  right: calc(var(--size) + 2vw);
  border-radius: 1.6rem;
  top: 0;
  width: 64%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.hero .bread[data-v-18c2bfa6] {
  position: absolute;
  left: calc(var(--size) + 2vw);
  bottom: 0;
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./hero.vue","webpack://./src/js/components/hero.vue"],"names":[],"mappings":"AAGA;6DAAA;AAgBA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACnBF;ADoBC;AARD;IASE,eAAA;ACjBA;AACF;ADkBC;AAXD;IAYE,eAAA;IACE,YAAA;ACfF;AACF;ADgBC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACbF;AACF;ADuHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACpHD;AC9DA;EACE,kBAAA;ADgEF;AC/DE;EACE,kBAAA;EACA,0CAAA;EACA,4CAAA;EACA,YAAA;EACA,UAAA;EACA,mDAAA;ADiEJ;AChEI;AACE;IACE,uBAAA;ADkEN;AChEI;IACE,yBAAA;ADkEN;AACF;AC/DE;EACE,kBAAA;EACA,uBAAA;EACA,cAAA;EACA,+EAAA;EACA,gBAAA;ADiEJ;AChEI;EACE,kBAAA;EACA,oBAAA;EACA,UAAA;ADkEN;AChEI;EACE,WAAA;EACA,UAAA;EACA,YAAA;EACA,mBF1BC;EE2BD,kBAAA;EACA,SAAA;EACA,OAAA;EACA,UAAA;EACA,gCAAA;ADkEN;AChEI;EACE,2BAAA;ADkEN;ACjEM;EACE,iCAAA;EACA,kBAAA;EACA,gBAAA;EACA,cFnCF;ACsGN;ACjEM;EACE,iBAAA;EACA,iBAAA;EACA,wBAAA;EACA,gCAAA;ADmER;AChEI;EACE,kBAAA;EACA,8BAAA;EACA,qBAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,UAAA;ADkEN;AC/DE;EACE,kBAAA;EACA,6BAAA;EACA,SAAA;EACA,UAAA;ADiEJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
