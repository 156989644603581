<template>
  <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="0"
    :loop="true"
    navigation
    :pagination="{ el: '.firstview__pager', clickable: true }"
    :scrollbar="{ draggable: true }"
    :speed = "1000"
    :autoplay="{ delay: 5000, disableOnInteraction: false }"
    effect="fade"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item, index) in list" :key="index">
      <img :src="item.pic" alt="photo">
    </swiper-slide>
  </swiper>
</template>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

<script>
import { speed } from 'jquery';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {}
    const onSlideChange = () => {}

    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade],
    }
  },
}
</script>
