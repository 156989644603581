<template>
  <nav>
    <ol itemscope itemtype="https://schema.org/BreadcrumbList">
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M180-140v-450l300-225.77L780-590v450H556.15v-267.69h-152.3V-140H180Z"/></svg>
        <a href="/" itemprop="item"><span itemprop="name">株式会社Dダイニング</span></a>
        <meta itemprop="position" content="1">
      </li>
      <li v-for="(item, index) in list" :key="index" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m517.85-480-184-184L376-706.15 602.15-480 376-253.85 333.85-296l184-184Z"/></svg>
        <a :href="item.link" itemprop="item" v-if="item.link"><span itemprop="name">{{ item.title }}</span></a>
        <span itemprop="item" v-else><span itemprop="name">{{ item.title }}</span></span>
        <meta itemprop="position" :content="index + 2">
      </li>
    </ol>
  </nav>
</template>

<style lang="scss" scoped>
nav{
  background: $white;
  padding: .9rem 2rem;
  padding-right: 2.4rem;
  border-radius: 999rem;
  transform: translateY(50%);
  box-shadow: .8rem .8rem 4rem rgba($normal,.08);
  ol{
    @include flex();
    align-items: center;
    justify-content: flex-start;
    li{
      @include flex();
      align-items: center;
      justify-content: flex-start;
      font-size: .92rem;
      &:first-child{
        svg{
          width: 1.24rem;
          height: 1.24rem;
          margin: 0 .4rem 0 0;
          transform: translateY(-.1rem);
        }
      }
      svg{
        width: 1rem;
        height: 1rem;
        display: inline-block;
        margin: 0 .8rem;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  }
}
</script>
