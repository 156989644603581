// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7139e23c]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7139e23c]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7139e23c]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7139e23c]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
footer[data-v-7139e23c] {
  background: rgb(237.9, 239.325, 236.475);
  color: rgb(73.8, 39.5357142857, 0);
  position: relative;
  z-index: 10;
}
footer a[data-v-7139e23c] {
  color: rgb(73.8, 39.5357142857, 0);
}
footer .curve[data-v-7139e23c] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  fill: rgb(237.9, 239.325, 236.475);
  transform: translateY(-100%);
}
footer .pagetop[data-v-7139e23c] {
  background: rgb(237.9, 239.325, 236.475);
  display: block;
  text-align: center;
  padding: 1rem 0;
}
footer .max[data-v-7139e23c] {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
footer .company[data-v-7139e23c] {
  text-align: center;
}
footer .company figure[data-v-7139e23c] {
  width: 14rem;
  margin: 0 auto;
}
footer .company figure img[data-v-7139e23c] {
  width: 100%;
  object-fit: cover;
}
footer .company dl[data-v-7139e23c] {
  line-height: 1.56;
  margin: 1.6rem 0 0;
}
footer .company dl dt[data-v-7139e23c] {
  font-weight: 700;
}
footer .company dl dd[data-v-7139e23c] {
  margin: 0.4rem 0 0;
}
footer nav[data-v-7139e23c] {
  margin: 4rem 0 0;
}
footer nav ul[data-v-7139e23c] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
footer nav ul li[data-v-7139e23c] {
  margin: 0 3.2rem 0 0;
}
footer nav ul li[data-v-7139e23c]:last-child {
  margin: 0;
}
footer nav ul li a[data-v-7139e23c] {
  font-size: 0.92rem;
}
footer nav ul li a[data-v-7139e23c]:hover {
  text-decoration: underline;
}
footer small[data-v-7139e23c] {
  display: block;
  text-align: center;
  font-size: 0.72rem;
  border-top: rgba(73.8, 39.5357142857, 0, 0.08) 0.1rem solid;
  padding: 2.4rem 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./footers.vue","webpack://./src/js/components/footers.vue"],"names":[],"mappings":"AAGA;6DAAA;AAgBA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACnBF;ADoBC;AARD;IASE,eAAA;ACjBA;AACF;ADkBC;AAXD;IAYE,eAAA;IACE,YAAA;ACfF;AACF;ADgBC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACbF;AACF;ADuHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACpHD;AC9DA;EACE,wCAAA;EACA,kCFCO;EEAP,kBAAA;EACA,WAAA;ADgEF;AC/DE;EACE,kCFHK;ACoET;AC/DE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;EACA,4BAAA;ADiEJ;AC/DE;EACE,wCAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;ADiEJ;AC/DE;EACE,iBAAA;EACA,oBAAA;ADiEJ;AC/DE;EACE,kBAAA;ADiEJ;AChEI;EACE,YAAA;EACA,cAAA;ADkEN;ACjEM;EACE,WAAA;EACA,iBAAA;ADmER;AChEI;EACE,iBAAA;EACA,kBAAA;ADkEN;ACjEM;EACE,gBAAA;ADmER;ACjEM;EACE,kBAAA;ADmER;AC/DE;EACE,gBAAA;ADiEJ;AChEI;EFFH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EECI,mBAAA;EACA,uBAAA;ADqEN;ACpEM;EACE,oBAAA;ADsER;ACrEQ;EACE,SAAA;ADuEV;ACrEQ;EACE,kBAAA;ADuEV;ACtEU;EACE,0BAAA;ADwEZ;AClEE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,2DAAA;EACA,iBAAA;ADoEJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
