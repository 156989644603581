<template>
  <div class="hero">
    <div class="title">
      <div>
        <p v-html="eng"></p>
        <h1 v-html="title"></h1>
      </div>
      <img :src="pic" :alt="title" v-if="pic">
    </div>
    <div class="bread">
      <slot></slot>
    </div>
    <img src="/images/common/circle.svg" alt="#ffbc0d" class="circle">
  </div>
</template>

<style lang="scss" scoped>
.hero{
  position: relative;
  .circle{
    position: absolute;
    right: calc(#{side()} * .5);
    bottom: calc(#{side()} * -.5);
    width: 10rem;
    z-index: 3;
    animation: rotation 8000ms linear infinite;
    @keyframes rotation {
      0%{
        transform: rotate(0deg);
      }
      100%{
        transform: rotate(360deg);
      }
    }
  }
  .title{
    position: relative;
    margin: var(--head) 0 0;
    line-height: 1;
    padding: 7.2rem side2x() calc(var(--head) + 7.2rem);
    overflow: hidden;
    > *{
      position: relative;
      align-self: flex-end;
      z-index: 3;
    }
    &:before{
      content: '';
      width: 41%;
      height: 150%;
      background: $gray;
      position: absolute;
      top: -25%;
      left: 0;
      z-index: 2;
      border-radius: 0 999rem 999rem 0;
    }
    > div{
      transform: translateY(2rem);
      p{
        font-family: "Outfit", sans-serif;
        font-size: 1.72rem;
        font-weight: 600;
        color: $sub;
      }
      h1{
        font-size: 3.2rem;
        margin: 2vmin 0 0;
        letter-spacing: .32vmin;
        transform: translateX(-.32vmin);
      }
    }
    img{
      position: absolute;
      right: side();
      border-radius: 1.6rem;
      top: 0;
      width: 64%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
  .bread{
    position: absolute;
    left: side();
    bottom: 0;
    z-index: 3;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    eng: {
      type: String,
      required: false
    },
    pic: {
      type: String,
      required: false
    },
  }
}
</script>
