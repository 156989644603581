// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7653fc8a]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7653fc8a]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7653fc8a]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7653fc8a]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
header[data-v-7653fc8a] {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.32rem calc(var(--size) + 2vw);
  background: #fdfefb;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  transition: all 200ms cubic-bezier(0.65, 0, 0.35, 1);
}
header.active[data-v-7653fc8a] {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  box-shadow: 0 0 0.32rem rgba(73.8, 39.5357142857, 0, 0.08);
}
header[data-v-7653fc8a] > * {
  align-self: center;
}
header .logo[data-v-7653fc8a] {
  width: 11rem;
  justify-self: flex-start;
}
header .logo img[data-v-7653fc8a] {
  width: 100%;
}
header nav[data-v-7653fc8a] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
header nav ul[data-v-7653fc8a] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
header nav ul li[data-v-7653fc8a] {
  font-size: 1.04rem;
  margin: 0 2rem 0 0;
  padding: 0 2rem 0 0;
  position: relative;
}
header nav ul li[data-v-7653fc8a]:after {
  content: "";
  width: 1px;
  height: 64%;
  border-left: rgb(73.8, 39.5357142857, 0) 1px dotted;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) skewX(-20deg);
  opacity: 0.32;
}
@media screen and (max-width: 1023px) {
header nav ul li[data-v-7653fc8a]:after {
    display: none;
}
}
header nav ul li[data-v-7653fc8a]:last-child {
  margin-right: 0;
  padding-right: 0;
}
header nav ul li[data-v-7653fc8a]:last-child::after {
  display: none;
}
header nav ul li a[data-v-7653fc8a] {
  color: rgb(73.8, 39.5357142857, 0);
  font-weight: 500;
}
header nav ul li a[data-v-7653fc8a]:hover {
  color: #db0007;
}
header .btn[data-v-7653fc8a] {
  justify-self: flex-end;
  color: #fdfefb;
  border: #db0007 1px solid;
  font-size: 1.08rem;
  line-height: 1.5;
  padding: 0.8rem 3.2rem;
  border-radius: 999rem;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  z-index: 1;
  transition: all 320ms cubic-bezier(0.65, 0, 0.35, 1);
  transition-property: color, fill;
  fill: #fdfefb;
}
header .btn svg[data-v-7653fc8a] {
  width: 1.32rem;
  height: 1.32rem;
  display: inline-block;
  margin: 0 0 0 0.8rem;
}
header .btn[data-v-7653fc8a]:before {
  content: "";
  background: #db0007;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn[data-v-7653fc8a]:hover {
  color: #db0007;
  fill: #db0007;
}
header .btn[data-v-7653fc8a]:hover:before {
  left: auto;
  right: 0;
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./headers.vue","webpack://./src/js/components/headers.vue"],"names":[],"mappings":"AAGA;6DAAA;AAgBA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACnBF;ADoBC;AARD;IASE,eAAA;ACjBA;AACF;ADkBC;AAXD;IAYE,eAAA;IACE,YAAA;ACfF;AACF;ADgBC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACbF;AACF;ADuHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpHD;ADsHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACpHD;AC9DA;EACE,WAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,wCAAA;EACA,mBAAA;EACA,aAAA;EACA,gCAAA;EACA,aAAA;EACA,oDAAA;ADgEF;AC/DE;EACE,mBAAA;EACA,sBAAA;EACA,0DAAA;ADiEJ;AC/DE;EACE,kBAAA;ADiEJ;AC/DE;EACE,YAAA;EACA,wBAAA;ADiEJ;AChEI;EACE,WAAA;ADkEN;AC/DE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;ADiEJ;AChEI;EFiBH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EElBI,uBAAA;EACA,mBAAA;ADqEN;ACpEM;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;ADsER;ACrEQ;EACE,WAAA;EACA,UAAA;EACA,WAAA;EACA,mDAAA;EACA,kBAAA;EACA,QAAA;EACA,QAAA;EACA,6CAAA;EACA,aAAA;ADuEV;ACtEU;AAVF;IAWI,aAAA;ADyEV;AACF;ACvEQ;EACE,eAAA;EACA,gBAAA;ADyEV;ACxEU;EACE,aAAA;AD0EZ;ACvEQ;EACE,kCF3DD;EE4DC,gBAAA;ADyEV;ACxEU;EACE,cFtDN;ACgIN;ACpEE;EACE,sBAAA;EFkCF,cApGM;EAqGN,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBALiD;EAMjD,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,oDAAA;EACA,gCAAA;EACA,aAnHM;ACwJR;ADpCE;EACE,cAAA;EACA,eAAA;EACA,qBAAA;EACA,oBAAA;ACsCJ;ADpCE;EACE,WAAA;EACA,mBAvHE;EAwHF,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,sDAAA;ACsCJ;ADpCE;EACE,cAjIE;EAkIF,aAlIE;ACwKN;ADrCI;EACE,UAAA;EACA,QAAA;EACA,QAAA;ACuCN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
