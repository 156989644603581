<template>
  <div>
    <div class="pic" v-if="pic">
      <div>
        <p v-html="eng"></p>
        <h1 v-html="title"></h1>
      </div>
      <img :src="pic" :alt="title">
    </div>
    <div class="bread">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pic{
  position: relative;
  padding: calc(100% / 16 * 4) 0 0;
  min-height: 20rem;
  &:before{
    content: '';
    width: 100%;
    height: 100%;
    background: $normal;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .4;
    z-index: 2;
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: $white;
    text-align: center;
    line-height: 1.5;
    font-weight: 700;
    p{
      font-size: 3.6rem;
    }
    h1{
      font-size: 1.12rem;
    }
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    eng: {
      type: String,
      required: false
    },
    pic: {
      type: String,
      required: false
    },
  }
}
</script>
