// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-002c74de]:root{font-size:calc(14.5px + 1.5*(100vw - 1440px)/480);--cx: 0;--cy: 0;--vw: 100vw;--vh: 100vh;--screen: 0px;--size: 32px}@media screen and (min-width: 1920px){[data-v-002c74de]:root{font-size:16px}}@media screen and (max-width: 1023px){[data-v-002c74de]:root{font-size:14px;--size: 16px}}@media screen and (max-width: 769px){[data-v-002c74de]:root{font-size:13.5px;--size: 10px}}@font-face{font-family:"Yu Gothic";src:local("Yu Gothic Medium");font-weight:100}@font-face{font-family:"Yu Gothic";src:local("Yu Gothic Medium");font-weight:200}@font-face{font-family:"Yu Gothic";src:local("Yu Gothic Medium");font-weight:300}@font-face{font-family:"Yu Gothic";src:local("Yu Gothic Medium");font-weight:400}@font-face{font-family:"Yu Gothic";src:local("Yu Gothic Medium");font-weight:500}@font-face{font-family:"Yu Gothic";src:local("Yu Gothic Bold");font-weight:bold}span[data-v-002c74de]{display:inline-block}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./src/js/components/slice.vue"],"names":[],"mappings":"AA4BA,uBACC,iDAAA,CACA,OAAA,CACA,OAAA,CACA,WAAA,CACA,WAAA,CACC,aAAA,CACA,YAAA,CACD,sCARD,uBASE,cAAA,CAAA,CAED,sCAXD,uBAYE,cAAA,CACE,YAAA,CAAA,CAEH,qCAfD,uBAgBE,gBAAA,CACE,YAAA,CAAA,CA0GJ,WACC,uBAAA,CACA,6BAAA,CACA,eAAA,CAED,WACC,uBAAA,CACA,6BAAA,CACA,eAAA,CAED,WACC,uBAAA,CACA,6BAAA,CACA,eAAA,CAED,WACC,uBAAA,CACA,6BAAA,CACA,eAAA,CAED,WACC,uBAAA,CACA,6BAAA,CACA,eAAA,CAED,WACC,uBAAA,CACA,2BAAA,CACA,gBAAA,CCjLD,sBACE,oBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
