<template>
	<footer id="site-footer" class="in-module" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
    <svg class="curve" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 725.63 28.2"><path d="M725.61,28.2c0-.05.02-.11.03-.16C484.95-9.35,240.69-9.35,0,28.04c0,.05.02.11.03.16h725.58Z"/></svg>
    <div class="max">
      <div class="company">
        <figure><img src="/images/common/logo.webp" alt="株式会社Dダイニング"></figure>
        <dl>
          <dt>株式会社Dダイニング</dt>
          <dd>千葉県浦安市北栄1-2-25 アドバンスビル<br>TEL.047-711-2025</dd>
        </dl>
      </div>
      <nav>
        <ul>
          <li itemprop="name"><a itemprop="url" href="/">インデックス</a></li>
          <li itemprop="name"><a itemprop="url" href="/about/">会社概要</a></li>
          <li itemprop="name"><a itemprop="url" href="/store/">店舗一覧</a></li>
          <li itemprop="name"><a itemprop="url" href="/recruit/">採用情報</a></li>
          <li><a href="/" target="_blank" rel="nofollow">求人エントリー</a></li>
        </ul>
      </nav>
    </div>
    <small class="copy">Copyright &copy; {{ currentYear }} Zenkei All rights reserved.</small>
	</footer>
</template>

<style lang="scss" scoped>
footer{
  background: darken($gray, 2%);
  color: $normal;
  position: relative;
  z-index: 10;
  a{
    color: $normal;
  }
  .curve{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    fill: darken($gray, 2%);
    transform: translateY(-100%);
  }
  .pagetop{
    background: darken($gray, 2%);
    display: block;
    text-align: center;
    padding: 1rem 0;
  }
  .max{
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .company{
    text-align: center;
    figure{
      width: 14rem;
      margin: 0 auto;
      img{
        width: 100%;
        object-fit: cover;
      }
    }
    dl{
      line-height: 1.56;
      margin: 1.6rem 0 0;
      dt{
        font-weight: 700;
      }
      dd{
        margin: .4rem 0 0;
      }
    }
  }
  nav{
    margin: 4rem 0 0;
    ul{
      @include flex();
      align-items: center;
      justify-content: center;
      li{
        margin: 0 3.2rem 0 0;
        &:last-child{
          margin: 0;
        }
        a{
          font-size: .92rem;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
  small{
    display: block;
    text-align: center;
    font-size: .72rem;
    border-top: rgba($normal,.08) .1rem solid;
    padding: 2.4rem 0;
  }
}
</style>

<script>
export default {
	data() {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>